import { inject, Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import {
  AuthApiActions,
  AuthModuleActions,
  LayoutSidenavActions,
  LoginPageActions,
  LoginRedirectPageActions,
  MicrosoftApiActions,
} from './auth.actions';
import { catchError, concatMap, map, of, switchMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationService } from '@core/services/notification.service';
import { AuthHelperService } from '../services/auth-helper.service';
import { AuthApiService } from '../services/auth-api.service';

@Injectable()
export class AuthEffects implements OnInitEffects {
  private actions$ = inject(Actions);
  private $router = inject(Router);
  private $notification = inject(NotificationService);
  private $authHelper = inject(AuthHelperService);
  private $authApi = inject(AuthApiService);

  ngrxOnInitEffects(): Action {
    return AuthModuleActions.checkInitialCookieWithTheToken();
  }

  checkInitialToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthModuleActions.checkInitialCookieWithTheToken),
      map(() => {
        const { token } = this.$authHelper;
        if (token) {
          return AuthModuleActions.setTheInitialToken({ token });
        }
        return AuthModuleActions.thereIsNoInitialToken();
      })
    )
  );

  enterLoginPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginPageActions.enterThePage),
        tap((action) => {
          if (this.$authHelper.isAuthenticated) {
            this.$router.navigate([action.returnUrl]);
          }
        })
      ),
    { dispatch: false }
  );

  loginViaCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginPageActions.loginViaCredentials),
      concatMap((action) =>
        this.$authApi.loginWithUserCredentials(action.credentials).pipe(
          map((response) => response.token),
          tap((encodedToken) => {
            this.$authHelper.removeAllCookies();
            this.$authHelper.setCookieWithToken(encodedToken);
            this.$router.navigate([action.returnUrl]);
          }),
          map((token) => AuthApiActions.successfullyLoggedIn({ token })),
          catchError((error) => {
            this.$notification.showError(error.error.message);
            return of(
              AuthApiActions.failedToLogIn({
                error: new Error(error.error.message),
              })
            );
          })
        )
      )
    )
  );

  loginViaSSO$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginPageActions.loginViaSSO),
        tap((action) => {
          this.$authHelper.loginSSO(action.returnUrl);
        })
      ),
    { dispatch: false }
  );

  getMicrosoftAuthInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginRedirectPageActions.getMicrosoftAuthInformation),
      switchMap((action) =>
        this.$authApi.getAuthInformationFromMicrosoft(action.code).pipe(
          map((info) =>
            MicrosoftApiActions.successfullyGotAuthInformation({
              information: info,
              returnUrl: action.returnUrl,
            })
          ),
          catchError(() => of(MicrosoftApiActions.failedToGetAuthInformation()))
        )
      )
    )
  );

  setCookiesAfterSuccessfulMicrosoftAuthentication$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MicrosoftApiActions.successfullyGotAuthInformation),
        tap((action) => {
          this.$authHelper.setSsoCookie();
          this.$authHelper.setCookieWithRefreshToken(
            action.information.refresh_token
          );
        })
      ),
    { dispatch: false }
  );

  getJwtTokenAfterSuccessfulMicrosoftAuthentication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MicrosoftApiActions.successfullyGotAuthInformation),
      switchMap((action) =>
        this.$authApi
          .getJwtTokenByOAuthToken(action.information.access_token)
          .pipe(
            map((response) =>
              AuthApiActions.successfullyGotJwtTokenWithOAuthToken({
                token: response.token,
                returnUrl: action.returnUrl,
              })
            ),
            catchError(() =>
              of(AuthApiActions.failedToGetJwtTokenWithOAuthToken())
            )
          )
      )
    )
  );

  setCookieAfterSuccessfulEmsaAuthentication$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.successfullyGotJwtTokenWithOAuthToken),
        tap((action) => {
          this.$authHelper.setCookieWithToken(action.token);
        })
      ),
    { dispatch: false }
  );

  navigateToReturnUrlAfterSuccessfulEmsaAuthentication$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.successfullyGotJwtTokenWithOAuthToken),
        tap((action) => {
          this.$router.navigate([action.returnUrl]);
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          LayoutSidenavActions.logout,
          LoginRedirectPageActions.logout,
          AuthApiActions.failedToGetJwtTokenWithOAuthToken,
          MicrosoftApiActions.failedToGetAuthInformation
        ),
        tap(() => this.$authHelper.logout())
      ),
    { dispatch: false }
  );
}
